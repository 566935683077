<script setup>

import { reactive, ref } from "vue"
import { useToast } from "primevue/usetoast"

import { useUserStore } from "@/store/user"

const userStore = useUserStore()
const toast = useToast()

const user = reactive({})

const loading = ref(false)

const save = ($router) => {
  loading.value = true

  userStore.store(user).then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: 'Success', life: 3000})

    loading.value = false

    $router.replace(`/admins/${data.user.id}/edit`)
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

</script>

<template>
  <div class="block-section">
    <div class="block-header">
        <span class="block-title">
            <div>Show #{{ user.id }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>
    </div>
    <div class="block-content">
        <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
          <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <div>

              <label class="block text-900 font-medium mb-2" for="username">Username</label>
              <InputText v-model="user.username" id="username" type="text" class="w-full mb-3" />

              <label class="block text-900 font-medium mb-2" for="name">Name</label>
              <InputText v-model="user.name" id="name" type="text" class="w-full mb-3" />

              <label class="block text-900 font-medium mb-2" for="password">Password</label>
              <InputText v-model="user.password" id="password" type="password" class="w-full mb-3" />

            </div>

            <br/>

            <Button label="Save" class="w-full" :loading="loading" @click="save($router)" />
          </div>
        </div>
    </div>
  </div>
</template>
